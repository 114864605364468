@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/elevation";
@use '$styles/mediaqueries';

.component {
    position: absolute;
    z-index: elevation.$modals;

    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: elevation.$modals - 1;
        background: transparentize($color: colors.$neutral800, $amount: 0.5);
        opacity: 0;
        transform: scale(0);
        transition: opacity 500ms ease, transform 0ms ease 500ms;
    }
    &:has(:global(.video-modal)) {
        &::after  {
            background: transparentize($color: colors.$neutral800, $amount: 0.3);
        }
    }
    
    &.open {
        &::after {
            
            opacity: 1;
            transform: scale(1);
            transition: opacity 500ms ease, transform 0ms ease;
        }
    }

    > aside {
        z-index: elevation.$modals;
    }
}
